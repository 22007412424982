import React from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Theme, { Mq } from '../themes/default'
import ContactUsForm from '../molecules/contact-us-form'
import Layout from '../organisms/layout'
import HubSpotFormTag from '../organisms/hubSpotFormTag'
import { SubpageHero } from '../molecules/subpage-hero'
import SEO from '../organisms/seo'

const SalesLeadsFormContainer = styled.div`
  padding: 3rem 5%;
  height: 100%;
  overflow: hidden;

  ${Mq.md} {
    padding: 0 3% 6rem;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 3% 12% 0;
  }
`

const SalesLeadsPage = () => {
  const onSubmission = () => navigate('/thank-you')

  return (
    <Layout hideNavItems>
      <SEO description="BiasSync Sales Leads" title="Sales Leads" />
      <HubSpotFormTag formId={process.env.GATSBY_HUBSPOT_SALES_LEAD_FORM_ID} />
      <SubpageHero title="Want to Learn More?" text="Please fill out this form to schedule a demo with one of our sales professionals." />
      <Fade bottom={true} cascade={true} distance={Theme.fadeDistance}>
        <SalesLeadsFormContainer>
          <ContactUsForm onSubmission={onSubmission} contactType="webinar" ctaButtonText="Submit" />
        </SalesLeadsFormContainer>
      </Fade>
    </Layout>
  )
}

export default SalesLeadsPage
